import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { Http, Response} from '@angular/http';
import { BookService } from '../book.service';
import { book } from '../book';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Observable } from 'rxjs';
import { NgModel } from '@angular/forms';
import {Router} from '@angular/router';
import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { PagerService } from '../pager.service';
import * as _ from 'underscore';
import { log } from 'util';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';




@Component({
  selector: 'app-booklist',
  templateUrl: './booklist.component.html',
  styleUrls: ['./booklist.component.css']
})
export class BooklistComponent implements OnInit {
  
   
  //@BlockUI() blockUI: NgBlockUI;
//public token=localStorage.getItem('token');

private allItems: any={};
pager: any = {};
pagedItems: any=[];
  public list:any={};
  description:any=null;
  title:any = null;
  inputName: any;
  testUrl:any;
  testTitle:any;
  testWidth:any;
  testHeight:any;
  testDesp:any;
  testFileName:any;
  testId:any;
  testCmt:any;
  comment:any;
  public fileid: any;
  public link: any;

public test:boolean=false;

 

  
  constructor(private service:BookService,private http:HttpClient,private router:Router,private pageService:PagerService) { }
  bookObj:object={};
  

  clearInput(){
    this.inputName='';
    this.test1();
  }


  commentSave(comment,bookId){
    //bookId=this.data1._id;
    console.log(comment);
    console.log(bookId);
    this.service.commentSave(comment,bookId).subscribe(res=>{
      console.log(res);
      this.test1()
    })
      
  }
  
  FilterByName(name){
    this.service.FilterByName(name).subscribe(res=>{
      this.allItems=res;
      console.log("length =>allItems",this.allItems.length)
      if(this.allItems.length>0){
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
       this.setPage(1);
      }else{
        this.pagedItems = [];
        this.pager = {}
      }

    },err=>{
      console.log("error",err);
    })     
}

  openRegister(){
    this.router.navigate((['/register']));
  }

  addNewBook(){
    //this.blockUI.start('Loading...');
    
    var uploadInput: any = document.getElementById("oamfile");
  var files = uploadInput.files;
  if (files.length > 0) {
  var fd = new FormData();
  
  
  if(this.description != null && this.description != undefined){
    fd.append('description',this.description)
  }
  
  fd.append("oamfile", files[0]);
  console.log(fd);
  
  
   this.service.addNewBook(fd).subscribe(res=>{
     this.ngOnInit();
   })
    //this.service.getBookList();
  }
} 
 
logout(){
  this.router.navigate((['/login']));
  localStorage.clear();
}

  ngOnInit() {
    console.log('init')
    this.test1();
    
  }
  test1(){
    console.log("test1 works")
    this.service.getBookList()
    .subscribe((data:any) => {
        // set items to json response
        console.warn(data);
        
        this.allItems = data.books;
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
        console.log(this.allItems);
        console.log('~~~~',this.pagedItems)

        // initialize to page 1
        this.setPage(1);
    });
  }
  
  data:any={"_id":""};
  del(idd){
    this.data._id=idd;
    console.log(idd);
    
  }

  
  deleteBook(bookId){

    
    bookId=this.data._id;
   this.service.deleteBook(bookId,).subscribe(
   data=>{
     console.log(bookId);
     this.ngOnInit();
     //this.service.getBookList();
    // this.router.navigate(["/booklist"]);
   }
   );
   
   
  }
  setPage(page: number) {
    console.log("setPage",page)
    if (page < 1 || page > this.pager.totalPages) {
        return;
    }

    this.pager =  this.pageService.getPager(this.allItems.length, page); 
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
    
    console.log("pagedItems",this.pagedItems)
}

// downloadapicall(){
//   console.log('zhatest'+this.fileid);
//   this.service.download(this.fileid).subscribe(( res: any) =>{
    
//     this.link = res.url;
//     //console.log(res.url);
//     //window.open(res.url);
//   })
// }

save(id){
  this.fileid=id;
  this.service.download(this.fileid).subscribe(( res: any) =>{
    
    this.link = res.url;
    //console.log(res.url);
    //window.open(res.url);
  })
}



}
